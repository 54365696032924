module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="simpleFileUploadModal" class="modal fade" tabindex="-1" data-bs-keyboard="false">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 id="mldoUploadTitle" class="modal-title">' +
((__t = ( title )) == null ? '' : __t) +
'</h4>\n        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>\n      </div>\n      <div id="mldo-upload-modal-body" class="modal-body">\n        <form id="simpleUploadForm" action="' +
__e( uploadURL ) +
'" method="post" enctype="multipart/form-data" accept="application/json" target="file_upload_target">\n        	<input type="hidden" id="sessionId" name="x-mw-gds-session-id" value="' +
__e( sessionId ) +
'" />\n        	<input type="hidden" id="folder" name="folder" value="' +
__e( currentFolder ) +
'" />\n      		<label id="simpleUploadFormFileChooseLabel" for="fileInput" ';
 if (useFolderUpload) { ;
__p += 'class="composite" ';
 } ;
__p += '>' +
((__t = ( selectionText )) == null ? '' : __t) +
'</label>\n          ';
 if (useFolderUpload) { ;
__p += '\n            <label id="simpleUploadEmptyFolderUploadWarning" class="uploadWarning" for="fileInput">' +
((__t = ( simpleUploadEmptyFolderUploadWarning )) == null ? '' : __t) +
'</label>\n          ';
 } ;
__p += '\n          <input id="fileInput" type="file" name="file" multiple />\n      	</form>\n        <div id="progressContainer" class="progressContainer">\n          <div class="progressStatus progressStaticText"><span id="itemsBeingUploaded"></span></div>\n          <div id="statusTextContainer" class="uploadStatusTextContainer">\n            <div  class="uploadFileNameContainer"><span id="uploadFileName" class="uploadFileName"></span></div>\n            <div class="progressStatus"><span id="progressUpdateText"></span></div>\n          </div>\n          <hr style="height: 30px;" />\n          <div class="progress uploadProgress">\n              <div id="fileUploadProgress" class="progress-bar progress-bar-striped" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;animation-duration:0.3s"></div>\n          </div>\n        </div>\n        <div id="mldo-upload-notification-container" style="display:none;"></div>\n        <div id="uploadStatusTextContainer"><span class="uploadStatusText">' +
((__t = ( uploadStatusText )) == null ? '' : __t) +
'</span></div>\n      </div>\n      <div class="modal-footer">\n        <button id="nameResolveButton" type="button" class="btn btn-primary" data-bs-dismiss="modal" style="display: none;">' +
((__t = ( resolveButtonLabel )) == null ? '' : __t) +
'</button>\n        <button id="uploadButton" type="button" class="btn btn-primary" data-bs-dismiss="modal">' +
((__t = ( uploadButtonLabel )) == null ? '' : __t) +
'</button>\n        <button id="uploadCancelButton" type="button" class="btn btn-default" data-bs-dismiss="modal">' +
((__t = ( uploadCancelButtonLabel )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n\n  </div>\n</div>\n';

}
return __p
};
