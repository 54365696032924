module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div id="renamePromptDialog" class="modal" tabindex="-1">\n  <div class="modal-dialog">\n      <div class="modal-content">\n            <div class="modal-header">\n                <h4 id="renamePromptTitle" class="modal-title">' +
__e( promptTitle ) +
'</h4>\n                <button type="button" class="btn-close cancelRenamePrompt" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body">\n                <div id="rename_prompt_error_notification_group" class="form-group">\n                    <div id="mldo-error-container" class="importantDialogError">\n                      <span class="notificationImageError" aria-hidden="true"></span><span class="notificationContentTextError">' +
__e( promptLabel ) +
'</span>\n                    </div>\n                </div>\n                <div id="rename_prompt_validation_group" class="form-group">\n                    <input type="text" class="form-control" id="renamePromptInput" value="' +
__e( initialText ) +
'">\n                    <div class="inline_form_error" style="display:none">\n                        <div class="inline_form_error_arrow"></div>\n                        <div class="inline_form_error_msg"></div>\n                    </div>\n                    <div class="notificationImageError form-control-feedback" aria-hidden="true" style="display:none; top: 37px; right: 5px;"></div>\n                    <div id="inputError2Status" class="visually-hidden">' +
((__t = ( errorLabel )) == null ? '' : __t) +
'</div>\n                </div>\n            </div>\n            <div class="modal-footer">\n                <button type="button" id="renamePromptSubmit" class="btn btn-primary renamePromptSubmit" disabled="disabled">' +
((__t = ( renameButtonLabel )) == null ? '' : __t) +
'</button>\n                <button type="button" id="cancelRenamePrompt" class="btn btn-default cancelRenamePrompt" data-bs-dismiss="modal">' +
((__t = ( renameCancelButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n      </div\n  </div>\n</div>\n';

}
return __p
};
