module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-12 col-sm-6 col-md-5" id="mobile_search" aria-expanded="false">\n    <div id="search_nested_content_container">\n        <input id="search" class="conjoined_search" type="search" placeholder="' +
((__t = ( scopeCurrentDirectory )) == null ? '' : __t) +
'"\n               data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" autocomplete="off">\n        <div id="search_scope_curr_directory" style="display:inline-block">\n            <div class="input-group">\n                <div class="input-group-btn">\n                    <button type="button" class="btn btn_search_adjacent dropdown-toggle" data-bs-toggle="dropdown">\n                        <span class="search_scope_label">' +
((__t = ( currentDirectory )) == null ? '' : __t) +
'</span>\n                    </button>\n                    <ul class="dropdown-menu list-unstyled dropdown-menu-right" role="menu">\n                        <li>\n                            <a class="dropdown-item">' +
((__t = ( currentDirectory )) == null ? '' : __t) +
'</a>\n                        </li>\n                        ';
 /* istanbul ignore next */ if (addSearchDropdown) { ;
__p += '\n                        <li id="matlab_drive_search_scope_trigger">\n                            <a class="dropdown-item">' +
((__t = ( rootFolderNameMatlabDrive )) == null ? '' : __t) +
'</a>\n                        </li>\n                        ';
 } ;
__p += '\n                    </ul>\n                </div>\n                <div class="search_form_error" style="display:none">\n                    <div class="inline_form_error_arrow"></div>\n                    <div class="search_form_error_msg"></div>\n                </div>\n            </div>\n        </div>\n        <div id="search_scope_matlab_drive" style="display:none">\n            <div class="input-group">\n                <div class="input-group-btn">\n                    <button type="button" class="btn btn_search_adjacent dropdown-toggle" data-bs-toggle="dropdown">\n                        <span class="search_scope_label">' +
((__t = ( rootFolderNameMatlabDrive )) == null ? '' : __t) +
'</span>\n                    </button>\n                    <ul class="dropdown-menu list-unstyled dropdown-menu-right" role="menu">\n                        <li>\n                            <a class="dropdown-item">' +
((__t = ( rootFolderNameMatlabDrive )) == null ? '' : __t) +
'</a>\n                        </li>\n                        <li id="curr_dir_search_scope_trigger">\n                            <a class="dropdown-item">' +
((__t = ( currentDirectory )) == null ? '' : __t) +
'</a>\n                        </li>\n                    </ul>\n                </div>\n                <div class="search_form_error" style="display:none">\n                    <div class="inline_form_error_arrow"></div>\n                    <div class="search_form_error_msg"></div>\n                </div>\n            </div>\n        </div>\n        <button type="submit" class="btn icon-search btn_search_adjacent btn_search icon_16"\n                disabled="disabled"></button>\n    </div>\n    <div id="close_mobile_search">\n        <button type="submit" class="btn icon-remove btn_search pull-right icon_32 visible-xs"\n                data-toggle="collapse" href="#mobile_search" aria-expanded="true" aria-controls="mobile_search"></button>\n    </div>\n</div>\n<div id="mobile_search_actuator" style="display:none">\n    <button type="submit" class="btn icon-search btn_search_adjacent btn_search icon_16" data-toggle="collapse" href="#mobile_search" aria-expanded="false" aria-controls="mobile_search"></button>\n</div>';

}
return __p
};
