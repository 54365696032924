module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="folderChooserDialog" ';
 if(isEmbedded){ ;
__p += 'class="modal"';
 } else { ;
__p += 'class="modal fade"';
 } ;
__p += ' tabindex="-1">\n  <div class="modal-dialog">\n      <div class="modal-content">\n\n          <div id="folderChooserMainHeader" class="modal-header">\n            <h4 id="folderChooserTitle" class="modal-title">' +
__e( modalTitle ) +
'</h4>\n            <button type="button" class="btn-close cancelSharing" data-bs-dismiss="modal" aria-label="Close"></button>\n          </div>\n          <div id="folderChooserMainBody" class="modal-body">\n            <div id="folderChooserMainContents" class="folderChooserMainContents">\n              <div id="folder_chooser_error_notification_group" class="form-group">\n                <div id="mldo-error-container" class="importantDialogError" ';
 if(folderChooser){ ;
__p += 'style="display:none;"';
 } ;
__p += '></div>\n              </div>\n              ';
 if(showFileNameSelector){ ;
__p += '\n              <div id="folderChooserFileNameContainer">\n                <label for="fileNameInput">' +
((__t = ( fileNameLabel )) == null ? '' : __t) +
'</label><input type="text" name="folderChooserFileNameInput" id="folderChooserFileNameInput" value="' +
((__t = ( fileName )) == null ? '' : __t) +
'" tabindex="0"/>\n              </div>\n              ';
 } ;
__p += '\n              <div id="folderChooserFolderTreeContainer">\n                <div class="toolbar_container" ';
 if (!newFolderAction && !renameAction) { ;
__p += ' style="display:none;" ';
 } ;
__p += ' >\n                  <div class="container-fluid">\n                    <div class="generalToolbar">\n                          <div class="actionToolbar">\n                            <div class="actionIconContainer">\n                            ';
 if (newFolderAction) { ;
__p += '\n                              <div class="action_icon newFolder">\n                                <span class="horizontalIconContainer">\n                                  <div class="newFolderIcon"  data-bs-placement="bottom" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' title="' +
((__t = ( newFolderToolTipText )) == null ? '' : __t) +
'"></div>\n                                </span>\n                                <span class="iconText">' +
((__t = ( filesPageNewFolderLabel )) == null ? '' : __t) +
'</span>\n                              </div>\n                            ';
 } ;
__p += '\n                            ';
 if (renameAction) { ;
__p += '\n                              <div class="action_icon rename">\n                                <span class="horizontalIconContainer">\n                                  <div class="renameIcon" data-bs-placement="bottom" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' title="' +
((__t = ( renameToolTipText )) == null ? '' : __t) +
'"></div>\n                                </span>\n                                <span class="iconText">' +
((__t = ( filesPageRenameLabel )) == null ? '' : __t) +
'</span>\n                              </div>\n                            ';
 } ;
__p += '\n                            </div>\n                          </div>\n                      <div class="breadcrumbContainer"></div>\n                    </div>\n                  </div>\n                </div>\n                <div id="folderChooserView" class="treeView ';
 if(showFileNameSelector){ ;
__p += ' fileNameSelector ';
 } ;
__p += '"></div>\n              </div>\n            </div>\n          </div>\n          <div id="folderChooserMainFooter" class="modal-footer">\n            <div id="noSelectionWarning" class="warning">\n              <span>' +
((__t = ( noSelectionWarning )) == null ? '' : __t) +
'</span>\n            </div>\n            <div class="folderChooserButtonContainer">\n              <button type="button" id="chooseFolderButton" class="btn btn-primary folderChooser" tabindex="0">' +
((__t = ( choseFolderButton )) == null ? '' : __t) +
'</button>\n              <button type="button" id="closeFolderChooserButton" class="btn btn-default closeFolderChooserDialogButton" data-bs-dismiss="modal" tabindex="0">' +
((__t = ( closeButton )) == null ? '' : __t) +
'</button>\n            </div>\n          </div>\n\n        ';
 if (newFolderAction) { ;
__p += '\n        <div id="folderChooserNewFolderContainer" style="display:none"></div>\n        ';
 } ;
__p += '\n\n      </div>\n  </div>\n</div>\n';

}
return __p
};
