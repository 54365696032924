module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="createFolderDialog" class="modal fade" tabindex="-1">\n  <div class="modal-dialog">\n      <div class="modal-content">\n          <div class="modal-header">\n              <h4 id="createFolderTitle" class="modal-title">' +
((__t = ( modalTitle )) == null ? '' : __t) +
'</h4>\n              <button type="button" class="btn-close cancelNewFolder" data-bs-dismiss="modal" aria-label="Close"></button>\n          </div>\n          <div class="modal-body">\n              <div id="new_folder_error_notification_group" class="form-group">\n                  <div id="mldo-error-container" class="importantDialogError" style="display:none;"></div>\n              </div>\n              <div id="new_folder_validation_group" class="form-group">\n                  <label class="mldo-modal-label" for="folderNameInput">' +
((__t = ( inputLabel )) == null ? '' : __t) +
'</label>\n                  <input type="text" class="form-control" id="folderNameInput">\n                  <div class="inline_form_error" style="display:none">\n                      <div class="inline_form_error_arrow"></div>\n                      <div class="inline_form_error_msg"></div>\n                  </div>\n                  <div class="notificationImageError form-control-feedback" aria-hidden="true" style="display:none; top: 51px; right: 20px; position: absolute;"></div>\n                  <div id="inputError2Status" class="visually-hidden">' +
((__t = ( statusError )) == null ? '' : __t) +
'</div>\n              </div>\n          </div>\n          <div class="modal-footer">\n              <button type="button" id="" class="btn btn-primary createNewFolder" disabled="disabled">' +
((__t = ( createButton )) == null ? '' : __t) +
'</button>\n              <button type="button" class="btn btn-default cancelNewFolder" data-bs-dismiss="modal">' +
((__t = ( cancelButton )) == null ? '' : __t) +
'</button>\n          </div>\n      </div>\n  </div>\n</div>\n';

}
return __p
};
