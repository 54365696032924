module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="deleteItemDialog" class="modal fade" tabindex="-1">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title">' +
__e( deleteItemHeader ) +
'</h4>\n                <button type="button" class="btn-close cancelDeleteItem" data-bs-dismiss="modal"></button>\n            </div>\n            <div class="modal-body delete-item-body-prompt">\n                <p>' +
((__t = ( deleteItemBodyPrompt )) == null ? '' : __t) +
'</p>\n                ';
if (shouldShowUnshareOption) { ;
__p += '\n                <input id="shouldUnshareInput" class="should-unshare-checkbox" type="checkbox" name="shouldUnshare"/>\n                <label class="should-unshare-checkbox-label" for="shouldUnshareInput">' +
__e( shouldUnshareLabel ) +
'</label>\n                <p class="should-unshare-checkbox-explanation">' +
__e( shouldUnshareExplanation ) +
'</p>\n                ';
 } ;
__p += '\n            </div>\n            <div class="modal-footer">\n                <button type="button" class="btn btn-primary deleteItem" data-bs-dismiss="modal" tabindex="2">' +
((__t = ( deleteButtonLabel )) == null ? '' : __t) +
'</button>\n                <button type="button" class="btn btn-default cancelDeleteItem" data-bs-dismiss="modal" tabindex="1">' +
((__t = ( cancelButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};
