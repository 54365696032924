module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += ' <div id="inlineNewFolderHeader" class="modal-header">\n  <h4 id="inlineTitle" class="modal-title">' +
__e( modalTitle ) +
'</h4>\n   <button type="button" class="btn-close cancelNewFolder" data-bs-dismiss="modal" aria-label="Close"></button>\n</div>\n\n<div id="inlineNewFolderBody" class="modal-body">\n  <div id="inlineNewFolderContents" class="inlineNewFolderContents">\n    <div id="new_folder_error_notification_group" class="form-group">\n      <div id="mldo-error-container" class="importantDialogError" style="display:none;"></div>\n    </div>\n    <div id="new_folder_validation_group" class="form-group">\n      <label class="mldo-modal-label" for="folderNameInput">' +
((__t = ( inputLabel )) == null ? '' : __t) +
'</label>\n      <input type="text" class="form-control" id="folderNameInput" tabindex="0"/>\n      <div class="inline_form_error" style="display:none">\n          <div class="inline_form_error_arrow"></div>\n          <div class="inline_form_error_msg"></div>\n      </div>\n      <div class="notificationImageError form-control-feedback" aria-hidden="true" style="display:none; top: 51px; right: 20px; position: absolute;"></div>\n      <div id="inputError2Status" class="visually-hidden">' +
((__t = ( statusError )) == null ? '' : __t) +
'</div>\n    </div>\n  </div>\n</div>\n\n<div id="inlineNewFolderFooter" class="modal-footer">\n  <div class="inlineButtonContainer">\n    <button type="button" id="" class="btn btn-primary createNewFolder" disabled="disabled" tabindex="0">' +
((__t = ( createButton )) == null ? '' : __t) +
'</button>\n    <button type="button" class="btn btn-default cancelNewFolder" tabindex="0">' +
((__t = ( cancelButton )) == null ? '' : __t) +
'</button>\n  </div>\n</div>\n';

}
return __p
};
