module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="filesViewHeader" class="headerContainer">\n  ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n    <div class="myfilesTitlebar section_header level_3">\n        <div class="container-fluid">\n            <div class="section_header_content">\n                <div class="section_header_title">\n                    <h1 class="myfilesTitle">' +
__e( userFirstNameFiles ) +
'</h1>\n                </div>\n                <button type="button" class="navbar-toggler collapsed subnav" data-bs-toggle="collapse" data-bs-target=".mobile_subnav_content"> <span class="icon-arrow-down"></span> </button>\n            </div>\n            ';
 /* istanbul ignore next */ if (searchEnabled) { ;
__p += '\n                ' +
((__t = ( searchTemplate )) == null ? '' : __t) +
'\n            ';
 } ;
__p += '\n            <!-- Section Header: End -->\n            <!-- Mobile Local Nav: Start -->\n            <div id="mobile_subnav">\n                <div class="navbar-collapse collapse mobile_subnav_content">\n                    <ul class="nav navbar-nav subnav">\n                        <li class="facets-files active"><span class="iconText">' +
((__t = ( facetsFiles )) == null ? '' : __t) +
'</span></li>\n                        ';
 /* istanbul ignore next */  if (sharingEnabled) { ;
__p += '\n                        <li class="facets-sharing"><span class="iconText">' +
((__t = ( facetsSharing )) == null ? '' : __t) +
'</span></li>\n                        ';
 } ;
__p += '\n                        <li class="facets-trash"><span class="iconText">' +
((__t = ( facetsDeletedFiles )) == null ? '' : __t) +
'</span></li>\n                    </ul>\n                </div>\n            </div>\n            <!-- Mobile Local Nav: End -->\n        </div>\n        ';
 /* istanbul ignore next */  if (previewBannerEnabled) { ;
__p += '\n        <div class="previewBannerContainer">' +
((__t = ( previewBannerText )) == null ? '' : __t) +
'</div>\n        ';
 } ;
__p += '\n    </div>\n\n</div>\n\n<div id="filesView" class="treeView">\n\n  <div class="facets hidden-sm hidden-xs">\n    <div class="facets-files is-disabled">\n      <span class="iconText">' +
((__t = ( facetsFiles )) == null ? '' : __t) +
'</span>\n    </div>\n    ';
 /* istanbul ignore next */ if (sharingEnabled) { ;
__p += '\n    <div class="facets-sharing">\n      <span class="iconText">' +
((__t = ( facetsSharing )) == null ? '' : __t) +
'</span>\n    </div>\n    ';
 } ;
__p += '\n    <div class="facets-trash">\n      <span class="iconText">' +
((__t = ( facetsDeletedFiles )) == null ? '' : __t) +
'</span>\n    </div>\n    <div id="facetsQuotaContainer">\n      <ul class="list-unstyled">\n        <li class="quotaListItem"></li>\n      </ul>\n    </div>\n  </div>\n\n  <div class="right-column">\n\n    <div class="toolbar_container">\n      <div class="container-fluid">\n        <div class="generalToolbar">\n          <div class="actionToolbar">\n            <div class="actionIconContainer">\n                  <div class="action_icon open disabledIcon">\n                    <span class="horizontalIconContainer">\n                    <div class="openInMatlabOnlineIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( openToolTipText )) == null ? '' : __t) +
'">\n                    </div></span>\n                    <span class="iconText">' +
((__t = ( openLiveEditor )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon view disabledIcon">\n                    <span class="horizontalIconContainer">\n                    <div class="viewIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( viewToolTipText )) == null ? '' : __t) +
'">\n                    </div></span>\n                    <span class="iconText">' +
((__t = ( viewFile )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon newTabWindow disabledIcon"> <a id="viewFileMenuOpen" role="menuitem" href="#" target="_blank" rel="noopener noreferrer">\n                    <span class="horizontalIconContainer">\n                      <div class="newtabIcon" title="' +
((__t = ( viewInNewTabToolTipText )) == null ? '' : __t) +
'"></div>\n                    </span> <span class="iconText">' +
((__t = ( viewInNewTabLabel )) == null ? '' : __t) +
'</span></a>\n                  </div>\n                  ';
 /* istanbul ignore next */ if (supportDirectoryUpload) { ;
__p += '\n                    <div class="action_icon upload dropdown disabledIcon">\n                      <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" data-bs-delay=\'{ "show": 1000, "hide": 100 }\'>\n                        <div class="uploadFilesActionContainer">\n                          <span class="horizontalIconContainer"><div class="uploadFilesIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( uploadToolTipText )) == null ? '' : __t) +
'"></div></span>\n                          <span class="iconText">' +
((__t = ( uploadActionLabel )) == null ? '' : __t) +
'</span>\n                          <div class="caretContainer"><strong class="caret"></strong></div>\n                        </div>\n                      </a>\n                      <ul class="dropdown-menu">\n                        <li role="menuitem">\n                          <div class="action_icon uploadFiles disabledIcon">\n                            <span class="horizontalIconContainer"><div class="uploadMultipleFilesIcon" data-bs-container=".myfilesTitlebar" data-bs-toggle="tooltip" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( uploadToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="actionMenuText">' +
((__t = ( uploadFilesActionLabel )) == null ? '' : __t) +
'</span>\n                          </div>\n                        </li>\n                        <li role="menuitem">\n                          <div class="action_icon uploadFolder disabledIcon">\n                            <span class="horizontalIconContainer"><div class="uploadFolderIcon" data-bs-container=".myfilesTitlebar" data-bs-toggle="tooltip" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( uploadFolderToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="actionMenuText">' +
((__t = ( uploadFolderActionLabel )) == null ? '' : __t) +
'</span>\n                          </div>\n                        </li>\n                      </ul>\n                    </div>\n                  ';
 } else { ;
__p += '\n                    <div class="action_icon uploadFiles disabledIcon" data-bs-toggle="modal" data-bs-target="#simpleFileUploadModal" data-bs-backdrop="static">\n                      <span class="horizontalIconContainer"><div class="uploadFilesIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-placement="top" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' title="' +
((__t = ( uploadToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( uploadActionLabel )) == null ? '' : __t) +
'</span>\n                    </div>\n                  ';
 } ;
__p += '\n                  <div class="action_icon newFolder disabledIcon">\n                    <span class="horizontalIconContainer"><div class="newFolderIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( newFolderToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( newFolderActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  ';
 /* istanbul ignore next */ if (sharingEnabled) { ;
__p += '\n                  <div class="action_icon share dropdown disabledIcon">\n                    <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="right">\n                      <div class="shareActionContainer">\n                        <span class="horizontalIconContainer"><div class="shareIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( shareToolTipText )) == null ? '' : __t) +
'"></div></span>\n                        <span class="iconText">' +
((__t = ( shareActionLabel )) == null ? '' : __t) +
'</span>\n                        <div class="caretContainer"><strong class="caret"></strong></div>\n                      </div>\n                    </a>\n                    <ul class="dropdown-menu">\n                      ';
 /* istanbul ignore next */ if (personalInvitationsEnabled) { ;
__p += '\n                      <li role="menuitem">\n                        <div class="action_icon manage disabledIcon">\n                          <span class="horizontalIconContainer"><div class="manageIcon" data-bs-container=".myfilesTitlebar" data-bs-toggle="tooltip" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( manageToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="actionMenuText">' +
((__t = ( manageActionLabel )) == null ? '' : __t) +
'</span>\n                        </div>\n                      </li>\n                      ';
 } ;
__p += '\n                      <li role="menuitem">\n                        <div class="action_icon link disabledIcon">\n                          <span class="horizontalIconContainer"><div class="linkIcon" data-bs-container=".myfilesTitlebar" data-bs-toggle="tooltip" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( linkToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="actionMenuText">' +
((__t = ( linkActionLabel )) == null ? '' : __t) +
'</span>\n                        </div>\n                      </li>\n                    </ul>\n                  </div>\n                  ';
 } ;
__p += '\n                  <div class="action_icon downloadFiles disabledIcon">\n                    <span class="horizontalIconContainer"><div class="downloadFilesIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( downloadToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( downloadActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon rename disabledIcon">\n                    <span class="horizontalIconContainer"><div class="renameIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( renameToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( renameActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  ';
 /* istanbul ignore next */ if (moveEnabled) { ;
__p += '\n                  <div class="action_icon move disabledIcon">\n                    <span class="horizontalIconContainer"><div class="moveIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( moveToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( moveActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon copy disabledIcon">\n                    <span class="horizontalIconContainer"><div class="copyIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( copyToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( copyToActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  ';
 } else  { ;
__p += '\n                  <div class="action_icon cut disabledIcon">\n                    <span class="horizontalIconContainer"><div class="cutIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( cutToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( cutActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  ';
 /* istanbul ignore next */ if (copyEnabled) { ;
__p += '\n                  <div class="action_icon copy disabledIcon">\n                    <span class="horizontalIconContainer"><div class="copyIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( copyToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( copyActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  ';
 } ;
__p += '\n                  <div class="action_icon paste disabledIcon disabledIcon">\n                    <span class="horizontalIconContainer"><div class="pasteIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( pasteToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( pasteActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  ';
 } ;
__p += '\n                  <div class="action_icon deleteFile disabledIcon">\n                    <span class="horizontalIconContainer"><div class="deleteIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar"  data-bs-delay=\'{ "show": 1000, "hide": 100 }\'data-bs-placement="top" title="' +
((__t = ( deleteToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( deleteActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon viewFileInlineClose">\n                    <button id="closeViewFileInlineButton" type="button" class="btn-close closeViewFileInlineButton" aria-label="Close"></button>\n                  </div>\n                </div>\n          </div>\n          <div class="breadcrumbContainer"></div>\n            </div>\n      </div>\n    </div>\n\n    <div class="container-fluid">\n      <div id="inlineFileViewContainer" class="inlineFileViewContainer" style="display:none;"></div>\n      <div class="wrapper">\n        <div id="jstree" class="fileDataContainer"></div>\n      </div>\n    </div>\n\n    ';
 /* istanbul ignore next */ if (isInfoPanelEnabled) { ;
__p += '\n    <div class="info-panel">\n      <div class = "collapsable-panel">\n        <div class="openCloseButton"></div>\n        <span class="notificationImageInfo text-info" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-placement="left" title="' +
((__t = ( panelInfoIcon )) == null ? '' : __t) +
'"></span>\n        <div class="container-fluid">\n          <div style="padding-top: 0px; "><span class="infoPanelFolderIcon"></span><span id="folderNamePanel"></span></div>\n          <div>' +
((__t = ( panelOwnedBy )) == null ? '' : __t) +
': <span id="ownedByPanel"></span></div>\n          <div>' +
((__t = ( panelAccessType )) == null ? '' : __t) +
': <span id="accessTypePanel"></span></div>\n          <div>' +
((__t = ( panelSharedFolderRoot )) == null ? '' : __t) +
': <span id="sharedParentPathPanel"></span></div>\n          <div>' +
((__t = ( panelCreatedOn )) == null ? '' : __t) +
': <span id="createdOnPanel"></span></div>\n          <div>' +
((__t = ( panelModifiedOn )) == null ? '' : __t) +
': <span id="modifiedOnPanel"></span></div>\n          <div>' +
((__t = ( panelModifiedBy )) == null ? '' : __t) +
': <span id="modifiedByPanel"></span></div>\n          <div>' +
((__t = ( panelChildCount )) == null ? '' : __t) +
': <span id="childCountPanel"></span></div>\n        </div>\n      </div>\n    </div>\n    ';
 } ;
__p += '\n\n  </div>\n\n</div>\n\n<iframe id="file_upload_target" name="file_upload_target" src="" title="File Upload Target"></iframe>\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
