module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="initiateSharingDialog" ';
 if(isEmbedded){ ;
__p += 'class="modal"';
 } else { ;
__p += 'class="modal fade"';
 } ;
__p += ' tabindex="-1">\n  <div class="modal-dialog">\n      <div class="modal-content">\n          <div class="modal-header">\n            <h4 id="initiateSharingTitle" class="modal-title">' +
__e( modalTitle ) +
'</h4>\n            <button type="button" class="btn-close cancelSharing" data-bs-dismiss="modal" aria-label="Close"></button>\n          </div>\n          <div class="modal-body">\n            <div id="sharing_validation_group" class="form-group">\n              <div id="shareEmailInputContainer" class="shareEmailContainer">\n                <label class="mldo-modal-label" for="emailInput">' +
((__t = ( inputLabel )) == null ? '' : __t) +
'</label>\n                <input type="text" class="form-control" id="emailInput" placeholder="' +
((__t = ( emailPrompt )) == null ? '' : __t) +
'" >\n                <select id="selectedSharePermission" class="form-control sharingPermissionsSelect">\n                  <option value="read_write">' +
((__t = ( readWritePermissionName )) == null ? '' : __t) +
'&nbsp;</option>\n                  <option value="read_only">' +
((__t = ( readOnlyPermissionName )) == null ? '' : __t) +
'&nbsp;</option>\n                </select>\n                <div class="inline_form_error" style="display:none">\n                  <div class="inline_form_error_arrow"></div>\n                  <div class="inline_form_error_msg"></div>\n                </div>\n              </div>\n              <div id="guidanceTextContainer" class="multiEmailTextWarning">' +
((__t = ( multiEmailTextWarning )) == null ? '' : __t) +
'</div>\n              <div id="sharing_comments">\n                  <textarea class="form-control" id="shareCommentsInput" placeholder="' +
((__t = ( commentsPrompt )) == null ? '' : __t) +
'" maxlength="500" data-bs-rows="2"></textarea>\n              </div>\n              <button id="addInviteeButton" class="btn btn-primary" disabled="disabled">' +
((__t = ( addUserButtonLabel )) == null ? '' : __t) +
'</button>\n              <div id="commentLength"></div>\n            </div>\n            <div id="sharing_error_notification_group" class="form-group"  style="display:none;">\n              <div id="mldo-sharing-success-container" class="importantDialogSuccess" style="display:none;">\n                <div class="notificationImageSuccess" aria-hidden="true"></div>\n                <div class="sharingSuccessful">\n                  <a href="javascript:void(0);"></a>\n                </div>\n              </div>\n              <div id="mldo-sharing-error-container" class="importantDialogError" style="display:none;">\n                <div class="notificationImageError" aria-hidden="true"></div>\n                <div class="sharingErrorWarning">\n                  <a href="javascript:void(0);"></a>\n                </div>\n              </div>\n              <div id="sharingInviteeResultList" class="initiateSharingInviteeList"></div>\n            </div>\n            <div id="sharingInviteeListContainer" class="initiateSharingInviteeListContainer">\n              <h3 id="collaboratorListTitle" class="collaboratorListLabel">' +
((__t = ( collaboratorListLabel )) == null ? '' : __t) +
'</h3>\n              <div id="sharingInviteeList" class="initiateSharingInviteeList"></div>\n            </div>\n            <div class="createLinkContainer">\n              <p id="explainCreateLink" class="explainLink">' +
__e( explainCreateLinkText ) +
'</p>\n              <p id="explainCopyLink" class="explainLink">' +
__e( explainCopyLinkText ) +
'</p>\n              <input type="text" readonly="readonly" id="linkUrlContainer" name="linkrlContainer" value="">\n              <button id="createShareLink" class="btn-primary btn">' +
((__t = ( createLinkText )) == null ? '' : __t) +
'</button>\n              <button id="copyShareLink" class="btn-primary btn">' +
((__t = ( copyLinkText )) == null ? '' : __t) +
'</button>\n              <br/>\n              <div class="statsAndDeleteLinkContainer">\n                <div id="deleteShareLinkContainer" class="deleteLink">\n                  <a id="deleteShareLink" href="#">\n                    <div class="horizontalDeleteLinkIconContainer">\n                      <div class="iconText">' +
((__t = ( deleteLinkText )) == null ? '' : __t) +
'</div>\n                    </div>\n                  </a>\n                </div>\n                <div class="linkStatsContainer" aria-describedby="linkStatsContainer">\n                  <table aria-describedby="linkStatsTable">\n                    <tr>\n                      <td id="linkAcceptedText">\n                        ' +
((__t = ( activeUsersLabel )) == null ? '' : __t) +
'\n                      </td>\n                      <td id="linkAcceptedCount"></td>\n                    </tr>\n                  </table>\n                </div>\n              </div>\n              <div id="noShareOperationsWarning" class="noShareOperations">' +
((__t = ( noShareOperationsAvailable )) == null ? '' : __t) +
'</div>\n            </div>\n          </div>\n          <div class="modal-footer">\n            <div id="confirmDelCollaboratorContainer" class="delCollaboratorConfirm" style="display:none;">\n              <label id="delCollaboratorTitle"></label>\n              <p id="delCollaboratorWarning"></p>\n              <div class="confirmButtonContainer">\n                <button id="doCollaboratorDelete" class="btn-primary btn delete-btn" tabindex="2">' +
((__t = ( deleteCollaboratorButtonLabel )) == null ? '' : __t) +
'</button>\n                <button id="cancelCollaboratorDelete" class="btn-default btn" tabindex="1">' +
((__t = ( cancelButtonLabel )) == null ? '' : __t) +
'</button>\n              </div>\n            </div>\n            <div id="confirmDelLinkContainer" class="delLinkConfirm" style="display:none;">\n              <label>' +
((__t = ( deleteLinkConfirmation )) == null ? '' : __t) +
'</label>\n              <p id="explainDeleteLink" class="explainLink">' +
((__t = ( explainDeleteLinkText )) == null ? '' : __t) +
'</p>\n              <div class="confirmButtonContainer">\n                <button id="doLinkDelete" class="btn-primary btn delete-btn" tabindex="2">' +
((__t = ( deleteLinkButtonLabel )) == null ? '' : __t) +
'</button>\n                <button id="cancelLinkDelete" class="btn-default btn" tabindex="1">' +
((__t = ( cancelButtonLabel )) == null ? '' : __t) +
'</button>\n              </div>\n            </div>\n            <button type="button" id="initiateShareButton" class="btn btn-primary initiateSharing" disabled="disabled">' +
((__t = ( shareButton )) == null ? '' : __t) +
'</button>\n            <button type="button" id="returnButton" class="btn btn-primary">' +
((__t = ( returnButton )) == null ? '' : __t) +
'</button>\n            <button id="updateAccessTypeButton" class="btn btn-primary" disabled="disabled">' +
((__t = ( updateAccessTypeButtonLabel )) == null ? '' : __t) +
'</button>\n            <button type="button" id="closeInitiateShareButton" class="btn btn-default closeInitiateSharingDialogButton" data-bs-dismiss="modal" style="float: right; ">' +
((__t = ( closeButton )) == null ? '' : __t) +
'</button>\n          </div>\n      </div>\n  </div>\n</div>\n';

}
return __p
};
