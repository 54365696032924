module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="trashViewHeader" class="headerContainer">\n    ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n    <div class="myfilesTitlebar section_header level_3">\n        <div class="container-fluid">\n            <div class="section_header_content">\n                <div class="section_header_title">\n                    <h1 class="myfilesTitle">' +
__e( userFirstNameDeletedFiles ) +
'</h1>\n                </div>\n                <button type="button" class="navbar-toggler collapsed subnav" data-bs-toggle="collapse" data-bs-target=".mobile_subnav_content"> <span class="icon-arrow-down"></span> </button>\n            </div>\n            ';
 /* istanbul ignore next */ if (searchEnabled) { ;
__p += '\n                ' +
((__t = ( searchTemplate )) == null ? '' : __t) +
'\n            ';
 } ;
__p += '\n            <!-- Section Header: End -->\n            <!-- Mobile Local Nav: Start -->\n            <div id="mobile_subnav">\n                <div class="navbar-collapse collapse mobile_subnav_content">\n                    <ul class="nav navbar-nav subnav">\n                        <li class="facets-files"><span class="iconText">' +
((__t = ( facetsFiles )) == null ? '' : __t) +
'</span></li>\n                        ';
 /* istanbul ignore next */  if (sharingEnabled) { ;
__p += '\n                        <li class="facets-sharing"><span class="iconText">' +
((__t = ( facetsSharing )) == null ? '' : __t) +
'</span></li>\n                        ';
 } ;
__p += '\n                        <li class="facets-trash active"><span class="iconText">' +
((__t = ( facetsDeletedFiles )) == null ? '' : __t) +
'</span></li>\n                    </ul>\n                </div>\n            </div>\n            <!-- Mobile Local Nav: End -->\n        </div>\n        ';
 /* istanbul ignore next */  if (previewBannerEnabled) { ;
__p += '\n        <div class="previewBannerContainer">' +
((__t = ( previewBannerText )) == null ? '' : __t) +
'</div>\n        ';
 } ;
__p += '\n    </div>\n\n\n</div>\n\n<div id="trashView" class="treeView">\n\n  <div class="facets hidden-sm hidden-xs">\n    <div class="facets-files">\n      <span class="iconText">' +
((__t = ( facetsFiles )) == null ? '' : __t) +
'</span>\n    </div>\n    ';
 /* istanbul ignore next */  if (sharingEnabled) { ;
__p += '\n    <div class="facets-sharing">\n      <span class="iconText">' +
((__t = ( facetsSharing )) == null ? '' : __t) +
'</span>\n    </div>\n    ';
 } ;
__p += '\n    <div class="facets-trash is-disabled">\n      <span class="iconText">' +
((__t = ( facetsDeletedFiles )) == null ? '' : __t) +
'</span>\n    </div>\n    <div id="facetsQuotaContainer">\n      <ul class="list-unstyled">\n        <li class="quotaListItem"></li>\n      </ul>\n    </div>\n  </div>\n\n  <div class="right-column">\n\n    <div class="toolbar_container">\n      <div class="container-fluid">\n        <div class="generalToolbar">\n              <div class="actionToolbar">\n                <div class="actionIconContainer">\n                  <div class="action_icon restore disabledIcon">\n                    <span class="horizontalIconContainer"><div class="restoreIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( restoreToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( restoreActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon permanentlyDelete disabledIcon">\n                    <span class="horizontalIconContainer"><div class="deleteIcon" data-bs-toggle="tooltip"  data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( permanentlyDeleteToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( deleteActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon permanentlyDeleteAll disabledIcon">\n                    <span class="horizontalIconContainer"><div class="deleteAllIcon" data-bs-toggle="tooltip"  data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( permanentlyDeleteAllToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="iconText">' +
((__t = ( deleteAllActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <span class="retentionInfo notificationImageInfo text-info" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-placement="left" title="' +
((__t = ( deletionPolicyText )) == null ? '' : __t) +
'"</span>\n                </div>\n              </div>\n            </div>\n      </div>\n    </div>\n\n    <div class="container-fluid">\n      <div class="wrapper">\n            <div class="fileDataContainer" id="trashTree"></div>\n          </div>\n    </div>\n\n  </div>\n</div>\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
