module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div id="restoreFolderDialog" class="modal fade" tabindex="-1">\n  <div class="modal-dialog">\n      <div class="modal-content">\n            <div class="modal-header">\n                <h4 id="restoreFolderDialogTitle" class="modal-title">' +
__e( dialogTitle ) +
'</h4>\n                <button type="button" class="btn-close cancelRenamePrompt" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body">\n              <div id="restore_folder_error_notification_group" class="form-group">\n                  <div id="mldo-error-container" class="importantDialogError">\n                    <span class="notificationImageError" aria-hidden="true"></span><span class="notificationContentTextError">' +
__e( dialogWarning ) +
'</span>\n                  </div>\n              </div>\n              <div class="restoreFolderNormalText">\n                <p>' +
((__t = ( dialogText )) == null ? '' : __t) +
'</p>\n              </div>\n            </div>\n            <div class="modal-footer">\n                <button type="button" id="restoreFolderSubmit" class="btn btn-primary restoreFolderSubmit">' +
((__t = ( restoreButtonLabel )) == null ? '' : __t) +
'</button>\n                <button type="button" id="cancelRestoreFolder" class="btn btn-default cancelRestoreFolder" data-bs-dismiss="modal">' +
((__t = ( restoreCancelButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n      </div\n  </div>\n</div>\n';

}
return __p
};
