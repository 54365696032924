module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="previewViewHeader" class="headerContainer">\n  ' +
((__t = ( headerNavigation )) == null ? '' : __t) +
'\n\n  <div class="myfilesTitlebar section_header level_3">\n    <div class="container-fluid">\n      <div class="section_header_content">\n        <div class="section_header_title">\n          <h1 class="myfilesTitle">' +
__e( invitationPreviewTitle ) +
'</h1>\n        </div>\n        <button type="button" class="navbar-toggler collapsed subnav" data-bs-toggle="collapse" data-bs-target=".mobile_subnav_content"> <span class="icon-arrow-down"></span> </button>\n      </div>\n      <!-- Section Header: End -->\n      <!-- Mobile Local Nav: Start -->\n      <div id="mobile_subnav">\n        <div class="navbar-collapse collapse mobile_subnav_content">\n          <ul class="nav navbar-nav subnav">\n            <li class="facets-files"><span class="iconText">' +
((__t = ( facetsFiles )) == null ? '' : __t) +
'</span></li>\n            ';
 /* istanbul ignore next */  if (sharingEnabled) { ;
__p += '\n            <li class="facets-sharing"><span class="iconText">' +
((__t = ( facetsSharing )) == null ? '' : __t) +
'</span></li>\n            ';
 } ;
__p += '\n            <li class="facets-trash"><span class="iconText">' +
((__t = ( facetsDeletedFiles )) == null ? '' : __t) +
'</span></li>\n          </ul>\n        </div>\n      </div>\n      <!-- Mobile Local Nav: End -->\n    </div>\n    ';
 /* istanbul ignore next */  if (previewBannerEnabled) { ;
__p += '\n    <div class="previewBannerContainer">' +
((__t = ( previewBannerText )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n  </div>\n\n</div>\n\n<div id="previewView" class="treeView">\n  ';
 /* istanbul ignore next */ if (isUserAuthenticated) { ;
__p += '\n  <div class="facets hidden-sm hidden-xs">\n    <div class="facets-files">\n      <span class="iconText">' +
((__t = ( facetsFiles )) == null ? '' : __t) +
'</span>\n    </div>\n    ';
 /* istanbul ignore next */ if (sharingEnabled) { ;
__p += '\n    <div class="facets-sharing is-disabled">\n      <span class="iconText">' +
((__t = ( facetsSharing )) == null ? '' : __t) +
'</span>\n    </div>\n    ';
 } ;
__p += '\n    <div class="facets-trash">\n      <span class="iconText">' +
((__t = ( facetsDeletedFiles )) == null ? '' : __t) +
'</span>\n    </div>\n    <div id="facetsQuotaContainer">\n      <ul class="list-unstyled">\n        <li class="quotaListItem"></li>\n      </ul>\n    </div>\n  </div>\n  ';
 } ;
__p += '\n\n  <div class="right-column">\n    <div class="toolbar_container">\n      <div class="container-fluid">\n        <div class="generalToolbar">\n              <div class="actionToolbar">\n                <div class="actionIconContainer">\n                  ';
 /* istanbul ignore next */ if (invitationStatus === "ACCEPTED") { ;
__p += '\n                    <div class="action_icon goToDrive disabledIcon">\n                      <span class="horizontalIconContainer"><div class="goToDriveIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( goToDriveToolTipText )) == null ? '' : __t) +
'"></div></span>\n                      <span class="iconText">' +
((__t = ( goToDriveActionLabel )) == null ? '' : __t) +
'</span>\n                    </div>\n                  ';
 } else { ;
__p += '\n                    <div class="action_icon add dropdown disabledIcon">\n                      <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="right">\n                        <div class="addActionContainer">\n                          <span class="horizontalIconContainer"><div class="addIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( addToDriveToolTipText )) == null ? '' : __t) +
'"></div></span>\n                          <span class="iconText">' +
((__t = ( addActionLabel )) == null ? '' : __t) +
'</span>\n                          <div class="caretContainer"><strong class="caret"></strong></div>\n                        </div>\n                      </a>\n                      <ul class="dropdown-menu">\n                        <li role="menuitem">\n                          <div class="action_icon addToDrive disabledIcon">\n                            <span class="horizontalIconContainer"><div class="addToDriveIcon" data-bs-container=".myfilesTitlebar" data-bs-toggle="tooltip" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( acceptToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="actionMenuText">' +
((__t = ( addToDriveActionLabel )) == null ? '' : __t) +
'</span>\n                          </div>\n                        </li>\n                        ';
 /* istanbul ignore next */ if (isCopyToDriveEnabled) { ;
__p += '\n                          <li role="menuitem">\n                            <div class="action_icon copyToDrive disabledIcon">\n                              <span class="horizontalIconContainer"><div class="copyIcon" data-bs-container=".myfilesTitlebar" data-bs-toggle="tooltip" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( copyToDriveToolTipText )) == null ? '' : __t) +
'"></div></span> <span class="actionMenuText">' +
((__t = ( copyToDriveActionLabel )) == null ? '' : __t) +
'</span>\n                            </div>\n                          </li>\n                        ';
 } ;
__p += '\n                      </ul>\n                    </div>\n                    ';
 /* istanbul ignore next */ if (invitationType === "PERSONAL") { ;
__p += '\n                    <div class="action_icon declineInvitation disabledIcon">\n                      <span class="horizontalIconContainer"><div class="glyphicon glyphicon-remove" data-bs-container=".myfilesTitlebar" data-bs-toggle="tooltip" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( declineToolTipText )) == null ? '' : __t) +
'">\n                          <button type="button" class="btn-close" aria-label="Close" tabindex="0"></button></div></span>\n                      <span class="iconText">' +
((__t = ( declineActionLabel )) == null ? '' : __t) +
'</span>\n                    </div>\n                    ';
 } ;
__p += '\n                  ';
 } ;
__p += '\n                  ';
 /* istanbul ignore next */ if (invitationType !== "PERSONAL" || invitationAccessType === "OWNER") { ;
__p += '\n                  <div class="action_icon shareLink disabledIcon">\n                    <span class="horizontalIconContainer"><div class="shareIcon" data-bs-container=".myfilesTitlebar" data-bs-toggle="tooltip" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( shareLinkToolTipText )) == null ? '' : __t) +
'"></div></span>\n                    <span class="iconText">' +
((__t = ( shareLinkActionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  ';
 } ;
__p += '\n                  <div class="action_icon action_icon_divider"></div>\n                  <div class="action_icon open disabledIcon">\n                    <span class="horizontalIconContainer">\n                      <div class="openInMatlabOnlineIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( openToolTipText )) == null ? '' : __t) +
'">\n                      </div>\n                    </span>\n                    <span class="iconText">' +
((__t = ( openLiveEditor )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon view disabledIcon">\n                    <span class="horizontalIconContainer"><div class="viewIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( viewToolTipText )) == null ? '' : __t) +
'"></div></span>\n                    <span class="iconText">' +
((__t = ( viewFile )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon downloadFiles disabledIcon">\n                    <span class="horizontalIconContainer"><div class="downloadFilesIcon" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-delay=\'{ "show": 1000, "hide": 100 }\' data-bs-placement="top" title="' +
((__t = ( downloadToolTipText )) == null ? '' : __t) +
'"></div></span>\n                      <span class="iconText downloadSharedFolder">' +
((__t = ( downloadActionLabel )) == null ? '' : __t) +
'</span>\n                      <span class="iconText downloadSelection" style="display: none;">' +
((__t = ( downloadActionSelectionLabel )) == null ? '' : __t) +
'</span>\n                  </div>\n                  <div class="action_icon viewFileInlineClose">\n                    <button id="closeViewFileInlineButton" type="button" class="btn-close closeViewFileInlineButton" data-bs-dismiss="modal" aria-label="Close"></button>\n                  </div>\n                </div>\n              </div>\n\n              <div class="breadcrumbContainer">\n                ';
 /* istanbul ignore next */ if (isUserAuthenticated) { ;
__p += '\n                <div class="sharedContent"><a>' +
((__t = ( facetsSharing )) == null ? '' : __t) +
'</a></div>\n                ';
 } ;
__p += '\n              </div>\n            </div>\n      </div>\n    </div>\n    <div class="container-fluid">\n      <div id="inlineFileViewContainer" class="inlineFileViewContainer" style="display:none;"></div>\n      <div class="wrapper">\n            <div class="fileDataContainer" id="jstree"></div>\n          </div>\n    </div>\n\n    ';
 /* istanbul ignore next */ if (isInfoPanelEnabled) { ;
__p += '\n    <div class="info-panel">\n      <div class = "collapsable-panel">\n        <div class="openCloseButton"></div>\n        <span class="notificationImageInfo text-info infoPanelNotification" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-placement="left" title="' +
((__t = ( panelInfoIcon )) == null ? '' : __t) +
'"></span>\n        <div class="container-fluid">\n          <div style="padding-top: 0px; "><span class="infoPanelFolderIcon shared"></span><span id="folderNamePanel"></span></div>\n          <div>' +
((__t = ( panelOwnedBy )) == null ? '' : __t) +
': <span id="ownedByPanel"></span></div>\n          <div>' +
((__t = ( panelAccessType )) == null ? '' : __t) +
': <span id="accessTypePanel"></span>\n            <span class="notificationImageInfo text-info accessTypeInfo editAccessInfo" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-placement="bottom" title="' +
((__t = ( panelEditAccessTypeInfoIcon )) == null ? '' : __t) +
'"></span>\n            <span class="notificationImageInfo text-info accessTypeInfo viewAccessInfo" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-placement="bottom" title="' +
((__t = ( panelViewAccessTypeInfoIcon )) == null ? '' : __t) +
'"></span>\n            <span class="notificationImageInfo text-info accessTypeInfo ownerAccessInfo" data-bs-toggle="tooltip" data-bs-container=".myfilesTitlebar" data-bs-placement="bottom" title="' +
((__t = ( panelOwnerAccessTypeInfoIcon )) == null ? '' : __t) +
'"></span>\n          </div>\n          <div style="display: none;">' +
((__t = ( panelSharedFolderRoot )) == null ? '' : __t) +
': <span id="sharedParentPathPanel"></span></div>\n          <div>' +
((__t = ( panelCreatedOn )) == null ? '' : __t) +
': <span id="createdOnPanel"></span></div>\n          <div>' +
((__t = ( panelModifiedOn )) == null ? '' : __t) +
': <span id="modifiedOnPanel"></span></div>\n          ';
 /* istanbul ignore next */ if (isUserAuthenticated) { ;
__p += '\n          <div>' +
((__t = ( panelModifiedBy )) == null ? '' : __t) +
': <span id="modifiedByPanel"></span></div>\n          ';
 } ;
__p += '\n          <div>' +
((__t = ( panelFolderSize )) == null ? '' : __t) +
': <span id="folderSizePanel"></span></div>\n          <div>' +
((__t = ( panelChildCount )) == null ? '' : __t) +
': <span id="childCountPanel"></span></div>\n        </div>\n      </div>\n    </div>\n    ';
 } ;
__p += '\n\n  </div>\n\n</div>\n\n<div id="modalContainer" class="modalContainer"></div>\n';

}
return __p
};
