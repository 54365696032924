module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- Mobile TopNav: Start -->\n<div class="header d-lg-none blockContainer" id="header_mobile">\n    <nav class="navbar navbar-expand-lg navbar-dark" aria-label="nav_mobile">\n\n        <div class="container-fluid">\n            <div class="navbar-header">\n                <button type="button" class="navbar-toggler topnav_toggle" data-bs-toggle="collapse" data-bs-target="#topnav_collapse">\n                    <span class="visually-hidden">' +
((__t = ( toggleNavigationLabel )) == null ? '' : __t) +
'</span>\n                    <span class="icon-menu"></span>\n                </button>\n                <div class="appTitle">\n                    <div class="logoWrapper">\n                        <div class="matlabdrive-logo"></div>\n                    </div>\n                </div>\n                <div id="matrix_affordance_container_mobile" class="headernav_matrix"></div>\n            </div>\n                <div class="navbar-collapse collapse" id="matrix_collapse">\n                        <div class="matrix_menu_container_mobile" id="matrix_menu_container_mobile"></div>\n                    </div>\n                <div class="navbar-collapse collapse matlabDriveUserName" id="topnav_collapse">\n                        <ul class="nav navbar-nav" id="topnav">\n                            ';
 /* istanbul ignore next */ if (isUserAuthenticated) { ;
__p += '\n                            <li class="headernav_login dropdown">\n                                <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">' +
__e( userFirstName ) +
' <strong class="caret"></strong></a>\n                                <ul class="dropdown-menu" id="ml_drive_nav_mobile">\n                                    <li class="nav-item dropdown-username">' +
__e( userFirstName ) +
' ' +
__e( userLastName ) +
'</li>\n                                    <li class="nav-item dropdown-email">' +
__e( userEmailAddress ) +
'</li>\n                                    <li class="nav-item quotaListItem"></li>\n                                    <li class="nav-item dropdown-divider"></li>\n                                    <li class = "nav-item"><a role="menuitem" href="https://www.mathworks.com/products/matlab-drive.html?s_tid=MLD_MLDO#matlab-drive-connector" target="_blank" rel="noopener noreferrer">' +
((__t = ( connectorDownloadText )) == null ? '' : __t) +
'</a></li>\n                                    ';
 /* istanbul ignore next */ if(showHiddenFilesEnabled || additionalApplicationsEnabled){ ;
__p += '\n                                    <li class="nav-item dropdown-divider"></li>\n                                    ';
 } ;
__p += '\n                                    ';
 /* istanbul ignore next */ if(showHiddenFilesEnabled){ ;
__p += '\n                                    <li class="nav-item dropdown-showhiddenfiles">\n                                        <label>\n                                            <input class="checkbox-showhiddenfiles" type="checkbox" name="showhiddenfiles_mobile"/>&nbsp;' +
__e( showHiddenFilesLabel ) +
'\n                                        </label>\n                                    </li>\n                                    ';
 } ;
__p += '\n                                    ';
 /* istanbul ignore next */ if(additionalApplicationsEnabled){ ;
__p += '\n                                    <li class="nav-item dropdown-additionalApplications">\n                                        <label>' +
__e( chooseApplicationLabel ) +
'</label>\n                                        <div class="radio-additionalApplications"><label><input type="radio" value="" name="additionalApplications_mobile" checked=true/>&nbsp;' +
__e( defaultApplicationLabel ) +
'</label></div>\n                                        <div class="radio-additionalApplications"><label><input type="radio" value="addons" name="additionalApplications_mobile"/>&nbsp;' +
__e( addonApplicationLabel ) +
'</label></div>\n                                        <div class="radio-additionalApplications"><label><input type="radio" value="thingspeak" name="additionalApplications_mobile"/>&nbsp;' +
__e( thingspeakApplicationLabel ) +
'</label></div>\n                                        <div class="radio-additionalApplications"><label><input type="radio" value="matlab_grader" name="additionalApplications_mobile"/>&nbsp;' +
__e( graderApplicationLabel ) +
'</label></div>\n                                    </li>\n                                    ';
 } ;
__p += '\n                                    <li class="nav-item dropdown-divider"></li>\n                                    <li class="logoutContainer"><a id="menu-logout" role="menuitem" href="#">' +
((__t = ( logoutLabel )) == null ? '' : __t) +
'</a></li>\n                                </ul>\n                            </li>\n                            ';
 } else { ;
__p += '\n                            <li class="headernav_login"><a href="/sharing" id="loginLink">' +
((__t = ( loginLabel )) == null ? '' : __t) +
'</a></li>\n                            ';
 } ;
__p += '\n                            <li class="headernav_help"><a href="https://www.mathworks.com/help/matlab/cloud-file-storage.html" target="_blank" rel="noopener noreferrer" id="helpLink">' +
((__t = ( helpLabel )) == null ? '' : __t) +
'</a></li>\n                        </ul>\n                    </div>\n        </div>\n\n\n    </nav>\n</div>\n<!-- Mobile TopNav: End -->\n<!-- Desktop TopNav: Start -->\n<div class="header d-none d-lg-block blockContainer" id="header_desktop">\n    <nav class="navbar navbar-expand-lg navbar-light" aria-label="nav_desktop">\n        <div class="container-fluid">\n            <div class="navbar-brand">\n                <div class="appTitle">\n                    <div class="logoWrapper">\n                        <div class="matlabdrive-logo"></div>\n                    </div>\n                </div>\n            </div>\n            <div class="collapse navbar-collapse matlabDriveUserName" id="headernav_collapse">\n              <ul class="nav navbar-nav navbar-right" style="padding-top: 0px !important;" id="headernav">\n                  <li class="nav-item headernav_help"><a href="https://www.mathworks.com/help/matlab/cloud-file-storage.html" target="_blank" rel="noopener noreferrer" id="helpLink">' +
((__t = ( helpLabel )) == null ? '' : __t) +
'</a></li>\n                  ';
 /* istanbul ignore next */ if (isUserAuthenticated) { ;
__p += '\n                  <li class="nav-item headernav_login dropdown">\n                      <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" id="menu1">\n                          ' +
__e( userFirstName ) +
' <strong class="caret"></strong>\n                      </a>\n                      <ul class="dropdown-menu" id="ml_drive_nav">\n                          <li class="nav-item dropdown-username">' +
__e( userFirstName ) +
' ' +
__e( userLastName ) +
'</li>\n                          <li class="nav-item dropdown-email">' +
__e( userEmailAddress ) +
'</li>\n                          <li class="nav-item dropdown-divider"></li>\n                          <li class = "nav-item"><a class="dropdown-item" href="https://www.mathworks.com/products/matlab-drive.html?s_tid=MLD_MLDO#matlab-drive-connector" target="_blank" rel="noopener noreferrer">' +
((__t = ( connectorDownloadText )) == null ? '' : __t) +
'</a></li>\n                          ';
 /* istanbul ignore next */ if(showHiddenFilesEnabled || additionalApplicationsEnabled){ ;
__p += '\n                          <li class="nav-item dropdown-divider"></li>\n                          ';
 } ;
__p += '\n                          ';
 /* istanbul ignore next */ if(showHiddenFilesEnabled){ ;
__p += '\n                          <li class="nav-item dropdown-showhiddenfiles">\n                              <label>\n                                  <input class="checkbox-showhiddenfiles" type="checkbox" name="showhiddenfiles_desktop"/>&nbsp;' +
__e( showHiddenFilesLabel ) +
'\n                              </label>\n                          </li>\n                          ';
 } ;
__p += '\n                          ';
 /* istanbul ignore next */ if(additionalApplicationsEnabled){ ;
__p += '\n                          <li class="nav-item dropdown-additionalApplications">\n                              <label>' +
__e( chooseApplicationLabel ) +
'</label>\n                              <div class="radio-additionalApplications"><label><input type="radio" value="" name="additionalApplications_desktop" checked=true/>&nbsp;' +
__e( defaultApplicationLabel ) +
'</label></div>\n                              <div class="radio-additionalApplications"><label><input type="radio" value="addons" name="additionalApplications_desktop"/>&nbsp;' +
__e( addonApplicationLabel ) +
'</label></div>\n                              <div class="radio-additionalApplications"><label><input type="radio" value="thingspeak" name="additionalApplications_desktop"/>&nbsp;' +
__e( thingspeakApplicationLabel ) +
'</label></div>\n                              <div class="radio-additionalApplications"><label><input type="radio" value="matlab_grader" name="additionalApplications_desktop"/>&nbsp;' +
__e( graderApplicationLabel ) +
'</label></div>\n                          </li>\n                          ';
 } ;
__p += '\n                          <li class="nav-item dropdown-divider"></li>\n                          <li class="logoutContainer"><a id="menu-logout" class="dropdown-item" href="#">' +
((__t = ( logoutLabel )) == null ? '' : __t) +
'</a></li>\n                      </ul>\n                  </li>\n                  ';
 } else { ;
__p += '\n                  <li class="nav-item headernav_login"><a href="/sharing" id="loginLink">' +
((__t = ( loginLabel )) == null ? '' : __t) +
'</a></li>\n                  ';
 } ;
__p += '\n                  <li id="headernav_matrix" class="nav-item headernav_matrix"></li>\n              </ul>\n            </div>\n        </div>\n    </nav>\n</div>\n<!-- Desktop TopNav: End -->\n\n<div id="notificationArea" class="notificationsWrapper importantMessage"></div>';

}
return __p
};
