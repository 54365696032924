module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="viewFileInlineContainer" tabindex="-1">\n  <div class="viewFileInlineBody">\n    <div id="pleaseWaitDiv">\n      <h1>' +
((__t = ( waitText )) == null ? '' : __t) +
'</h1>\n      <div class="imageContainer throbber"></div>\n    </div>\n    <div id="viewFileErrorMessage"  class="viewFileErrorMessage" style="display:none;" >\n      <div class="notificationErrorWrapper"><span class="notificationImageError" aria-hidden="true"></span><span class="notificationContentTextError">' +
((__t = ( errorText )) == null ? '' : __t) +
'</span></div>\n    </div>\n\n    ';
 if (imageSrc !== "\"\"") { ;
__p += '\n      <div id="viewFileImageContainer">\n        <img id="viewFileImage" class="viewFileImage" src=' +
((__t = ( imageSrc )) == null ? '' : __t) +
' alt="file image" />\n      </div>\n    ';
 } ;
__p += '\n\n    ';
 if (iframeSrc !== "\"\"") { ;
__p += '\n      <div id="viewFileIframeContainer"class="ios_iframe_scroller">\n        <iframe id="viewFileIframe" class="viewFileIframe" src=' +
((__t = ( iframeSrc )) == null ? '' : __t) +
' title="View File" ></iframe>\n      </div>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';

}
return __p
};
