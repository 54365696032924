module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="openInMODialog" class="modal fade" tabindex="-1">\n  <div class="modal-dialog">\n      <div class="modal-content">\n          <div class="modal-header">\n              <h4 id="openInMOTitle" class="modal-title">' +
((__t = ( modalTitle )) == null ? '' : __t) +
'</h4>\n              <button type="button" class="btn-close openInMOClose" data-bs-dismiss="modal" aria-label="Close"></button>\n          </div>\n          <div class="modal-body">\n              ' +
((__t = ( openInMOLabel )) == null ? '' : __t) +
'\n          </div>\n          <div class="modal-footer">\n              <button type="button" id="" class="btn btn-primary addToDrive" data-bs-dismiss="modal">' +
((__t = ( addToDriveButton )) == null ? '' : __t) +
'</button>\n              <button type="button" class="btn btn-primary copyToDrive" data-bs-dismiss="modal">' +
((__t = ( copyToDriveButton )) == null ? '' : __t) +
'</button>\n          </div>\n      </div>\n  </div>\n</div>\n';

}
return __p
};
