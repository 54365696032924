module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="fileConflictDialog" class="modal" tabindex="-1">\n  <div class="modal-dialog">\n      <div class="modal-content">\n            <div class="modal-header">\n                <h4 id="fileConflictDialogTitle" class="modal-title">' +
__e( dialogTitle ) +
'</h4>\n                <button type="button" class="btn-close cancelFileConflict" data-bs-dismiss="modal" aria-label="Close"></button>\n            </div>\n            <div class="modal-body">\n              <div id="viewFileErrorMessage"  class="viewFileErrorMessage fileConflictBodyHeader">\n                <div class="notificationErrorWrapper"><span class="notificationImageError" aria-hidden="true"></span><span class="notificationContentTextError">' +
__e( dialogBodyText ) +
'</span></div>\n              </div>\n              <form action="">\n                <label id="selectAllLabel" class="conflictFileName"><input type="checkbox" name="selectAll" id="selectAllConflicts"><span class="conflictFileName">' +
((__t = ( selectAllConflictFiles )) == null ? '' : __t) +
'</span></label>\n                <div class="fileConflictListContainer form-group"></div>\n              </form>\n            </div>\n            <div class="modal-footer">\n              ';
if (!isFolder) { ;
__p += '\n                <button type="button" id="replaceFileButton" data-bs-loading-text="<span class=\'glyphicon-left glyphicon glyphicon-refresh spinning\'></span>&nbsp;' +
((__t = ( pleaseWaitText )) == null ? '' : __t) +
'" class="btn btn-primary replaceFileButton">' +
((__t = ( replaceButtonLabel )) == null ? '' : __t) +
'</button>\n              ';
 } ;
__p += '\n              <button type="button" id="keepFileSeparateButton" data-bs-loading-text="<span class=\'glyphicon-left glyphicon glyphicon-refresh spinning\'></span>&nbsp;' +
((__t = ( pleaseWaitText )) == null ? '' : __t) +
'" class="btn btn-default keepFileSeparateButton">' +
((__t = ( keepSeparateButtonLabel )) == null ? '' : __t) +
'</button>\n            </div>\n      </div>\n  </div>\n</div>\n';

}
return __p
};
