module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="viewFileDialog" class="modal fade" tabindex="-1">\n  <div class="modal-dialog modal-lg">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title">' +
((__t = ( fileName )) == null ? '' : __t) +
'</h4>\n        <button type="button" class="btn-close cancelDeleteItem" data-bs-dismiss="modal"></button>\n      </div>\n      <div class="viewFileMenu_container">\n        <div class="viewFileMenu" id="viewFileMenu" style="display:none">\n          <div class="container-fluid">\n            <div class="actionToolbar">\n              <div class="actionIconContainer">\n                ';
 if (downloadSupported) { ;
__p += '\n                <div class="action_icon downloadFiles"> <a role="menuitem" id="viewFileMenuDownload" href="#" target="_blank"><span class="horizontalIconContainer">\n                  <div class="downloadFilesIcon"></div>\n                </span> <span class="iconText">' +
((__t = ( download )) == null ? '' : __t) +
'</span></a> </div>\n                ';
 } ;
__p += '\n                <div class="action_icon deleteFile"> <a role="menuitem" id="viewFileMenuDelete" href="#" target="_blank"><span class="horizontalIconContainer">\n                  <div class="deleteIcon"></div>\n                </span> <span class="iconText">' +
((__t = ( deleteItem )) == null ? '' : __t) +
'</span></a> </div>\n                ';
 if (enableNewTabWindow) { ;
__p += '\n                <div class="action_icon newTabWindow"> <a id="viewFileMenuOpen" role="menuitem" href="#" target="_blank"><span class="horizontalIconContainer">\n                  <div class="newtabIcon"></div>\n                </span> <span class="iconText">' +
((__t = ( openNewTab )) == null ? '' : __t) +
'</span></a> </div>\n                ';
 } ;
__p += '\n                ';
 if (viewerServiceEnabled && liveEditorSupported) { ;
__p += '\n                <div class="action_icon newTabWindow"> <a id="openLiveEditor" role="menuitem" ';
 if (!mockOpenwith) { ;
__p += ' href="#" target="_blank"';
 } ;
__p += '><span class="horizontalIconContainer">\n                  <div class="openInMatlabOnlineIcon"></div>\n                </span> <span class="iconText">' +
((__t = ( openLiveEditor )) == null ? '' : __t) +
'</span></a> </div>\n                ';
 } ;
__p += '\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="modal-body viewFileDialogModalBody">\n        <div id="pleaseWaitDiv">\n          <h1>' +
((__t = ( waitText )) == null ? '' : __t) +
'</h1>\n          <div class="imageContainer throbber"></div>\n        </div>\n        <div id="viewFileErrorMessage"  class="viewFileErrorMessage" style="display:none;" >\n          <div class="notificationErrorWrapper"><span class="notificationImageError" aria-hidden="true"></span><span class="notificationContentTextError">' +
((__t = ( errorText )) == null ? '' : __t) +
'</span></div>\n        </div>\n        <img id="viewFileImage" class="viewFileImage" style="display:none;" src=' +
((__t = ( imageSrc )) == null ? '' : __t) +
' alt="file image"/>\n        <div id="viewFileIframeContainer"class="ios_iframe_scroller">\n          <iframe id="viewFileIframe" class="viewFileIframe" style="display:none;" src=' +
((__t = ( iframeSrc )) == null ? '' : __t) +
' title="View File"></iframe>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <button id = "closeViewFileDialogButton" type="button" class="btn btn-default closeViewFileDialogButton" data-bs-dismiss="modal">' +
((__t = ( closeButtonLabel )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};
